import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          Tab: {
            screens: {
              TabOne: {
                screens: {
                  DashboardScreen: "dashboard",
                },
              },
              TabTwo: {
                screens: {
                  NewOrderScreen: "new",
                },
              },
              TabThree: {
                screens: {
                  MyOrdersScreen: "my_requests",
                  RequestScreen: "request/:id",
                },
              },
            },
          },
        },
      },
      // Login: {
      //   screens: {
      Login: "login",
      //   },
      // },
      NotFound: "*",
    },
  },
};
