import { Platform, PlatformOSType } from "react-native";
import { Fonts } from "./interfaces";
// ios and android fonts confing
const fontConfig = {
  android: {
    regular: {
      fontFamily: "Tajawal-Regular",
    },
    medium: {
      fontFamily: "Tajawal-Medium",
    },
    light: {
      fontFamily: "Tajawal-Light",
    },
    bold: {
      fontFamily: "Tajawal-Bold",
    },
  },
  ios: {
    regular: {
      fontFamily: "Tajawal-Regular",
    },
    medium: {
      fontFamily: "Tajawal-Medium",
    },
    light: {
      fontFamily: "Tajawal-Light",
    },
    bold: {
      fontFamily: "Tajawal-Bold",
    },
  },
  web: {
    regular: {
      fontFamily: "Tajawal-Regular",
    },
    medium: {
      fontFamily: "Tajawal-Medium",
    },
    light: {
      fontFamily: "Tajawal-Light",
    },
    bold: {
      fontFamily: "Tajawal-Bold",
    },
  },
  default: {
    regular: {
      fontFamily: "Tajawal-Regular",
    },
    medium: {
      fontFamily: "Tajawal-Medium",
    },
    light: {
      fontFamily: "Tajawal-Light",
    },
    bold: {
      fontFamily: "Tajawal-Bold",
    },
  },
};
// handles the fonts for both ios and android
export default function configureFonts(
  config?: { [platform in PlatformOSType | "default"]?: Fonts }
): Fonts {
  const fonts = Platform.select({ ...fontConfig, ...config }) as Fonts;
  return fonts;
}
