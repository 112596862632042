import React from "react";
import { dark, light } from "./colors";
import { ThemeStore } from "./store";
import { useThemeService } from "./service";
import { Theme, ThemeKey } from "./interfaces";

interface ThemeContextType {
  theme: Theme;
  toggleTheme: (theme: ThemeKey) => any;
}

const initialValue: ThemeContextType = {
  theme: dark,
  toggleTheme: (theme: ThemeKey) => ThemeStore.setTheme(theme),
};

export const ThemeContext: React.Context<ThemeContextType> = React.createContext(
  initialValue
);

// return the ThemeProvider as a wrapper
export const ThemeProvider = ({
  children,
}: JSX.ElementChildrenAttribute): React.ReactElement => {
  const [theme, toggleTheme] = useThemeService();
  return (
    <ThemeContext.Provider
      value={{ theme: theme === "dark" ? dark : light, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
