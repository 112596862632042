import AsyncStorage from "@react-native-async-storage/async-storage";
import {ThemeKey} from "./interfaces";
// change the theme and store the user preferences in the user's device.
class ThemeStoreType {
  public setTheme(name: ThemeKey): Promise<any> {
    return AsyncStorage.setItem("theme", name) as Promise<any>;
  }

  public getTheme(): Promise<ThemeKey> {
    return AsyncStorage.getItem("theme") as Promise<ThemeKey>;
  }

  public toggleTheme(theme: ThemeKey): Promise<any> {
    return this.setTheme(theme);
  }
}

export const ThemeStore: ThemeStoreType = new ThemeStoreType();
