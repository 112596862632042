import React from "react";
import { Text as RNText, TextProps, StyleSheet } from "react-native";
import { useTheme } from "@react-navigation/native";
import { Theme } from "@theme";

interface Props extends TextProps {
  children?: React.ReactNode;
}
/**
 * renders custom styled text
 * @param props
 */
const ThemedText: React.FC<Props> = (props) => {
  const { colors, fonts } = useTheme() as Theme;
  return (
    <RNText
      {...props}
      style={[
        // styles.shadow,
        { color: colors.text, shadowColor: colors.primary, ...fonts?.regular },
        props.style,
      ]}
    />
  );
};
// component styles
const styles = StyleSheet.create({
  shadow: {
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.24,
    shadowRadius: 0.75,
  },
});
export const Text = React.memo(ThemedText);
