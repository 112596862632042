import React from "react";
import { View, StyleSheet, ViewProps, Image } from "react-native";
import { useTheme } from "@react-navigation/native";
// local imports
import { IS_WEB, SCREEN_HEIGHT, SCREEN_WIDTH } from "@utils/constants";
import { Theme } from "@theme";
// component interface
interface IProps extends ViewProps {
  children: React.ReactNode;
  fixed?: boolean; // useful when you have scrollable view but you need the header to stay still while scrolling.
}
/**
 * Header component responsible for rendering a curvy header
 * @param props
 */
const HeaderComponent: React.FC<IProps> = (props) => {
  const { colors } = useTheme() as Theme;
  return (
    <View
      style={[
        styles.containerStyle,
        props.fixed ? { position: "absolute" } : null,
      ]}>
      <View style={styles.contentContainerStyle}>
        <View
          style={[
            styles.content,
            { backgroundColor: colors.primary },
            props.style,
          ]}>
          <Image
            source={require("@assets/images/background.png")}
            style={{ width: "100%", height: "100%", position: "absolute" }}
            resizeMode="cover"
          />
          {props.children}
        </View>
      </View>
    </View>
  );
};
// component styles.
const styles = StyleSheet.create({
  containerStyle: {
    alignSelf: "center",
    width: IS_WEB ? "100%" : SCREEN_WIDTH,
    overflow: "hidden",
    height: IS_WEB ? SCREEN_HEIGHT * 0.5 : SCREEN_WIDTH * 0.9,
  },
  contentContainerStyle: {
    borderRadius: SCREEN_WIDTH,
    width: SCREEN_WIDTH * 2,
    height: SCREEN_WIDTH * 2,
    marginLeft: -(SCREEN_WIDTH / 2),
    position: "absolute",
    bottom: 0,
    overflow: "hidden",
  },
  content: {
    height: SCREEN_WIDTH * 0.9,
    width: SCREEN_WIDTH,
    flex: 1,
    position: "absolute",
    bottom: 0,
    marginLeft: SCREEN_WIDTH / 2,
  },
});

export const Header = React.memo(HeaderComponent);
