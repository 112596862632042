import { WorkflowStateProps } from "./interfaces";
export default {
  workflow: {
    states: [
      {
        id: "STATE__1",
        name_ar: "طلب جديد",
        name_en: "New Order",
        actions: [
          {
            id: "STATE__1_ACTION_1",
            deadend: true,
            name_ar: "طلب جديد",
            name_en: "New Order",
            to: [{ type: "observers" }, { type: "stakeholders" }],
            color: "#AA70FA",
          },
        ],
      },
      {
        id: "STATE__2",
        name_ar: "تحت المراجعة",
        name_en: "under review",
        actions: [
          {
            id: "STATE__2_ACTION_1",
            deadend: false,
            name_ar: "مقبول و يحال للتحكيم",
            name_en: "Accepted: Forwarded to Reviewers",
            to: [{ type: "reviewers" }, { type: "observers" }],
            color: "#5AB6F5",
          },
          {
            id: "STATE__2_ACTION_2",
            deadend: true,
            name_ar: "غير مقبول",
            name_en: "Rejected",
            to: [{ type: "author" }, { type: "observers" }],
            color: "red",
          },
        ],
      },
      {
        id: "STATE__3",
        name_ar: "أحيل للتحكيم",
        name_en: "Under Review By Referees",
        actions: [
          {
            id: "STATE__3_ACTION_1",
            deadend: true,
            name_ar: "صالح للنشر دون تعديلات",
            name_en: "Accepted",
            to: [{ type: "author" }, { type: "observers" }],
            color: "#7CCF3C",
          },
          {
            id: "STATE__3_ACTION_2",
            deadend: true,
            name_ar: "غير صالح للنشر",
            name_en: "Not suitable for Publication",
            to: [{ type: "author" }, { type: "observers" }],
            color: "#F36F71",
          },
          {
            id: "STATE__3_ACTION_3",
            deadend: false,
            name_ar: "صالح للنشر مع إجراء تعديلات",
            name_en: "Conditional Acceptance",
            to: [{ type: "author" }, { type: "observers" }],
            color: "#C3A057",
          },
        ],
      },
      {
        id: "STATE__4",
        name_ar: "تحت التعديل بواسطة المؤلف",
        name_en: "Under Modification by Auther",
        actions: [
          {
            id: "STATE__4_ACTION_1",
            deadend: false,
            name_ar: "تعديل الطلب",
            name_en: "Paper modifications",
            to: [{ type: "observers" }, { type: "stakeholders" }],
            color: "#4AD8EA",
          },
        ],
      },
    ],
    transitions: [
      {
        currentState: "STATE__1",
        nextState: "STATE__2",
        sequence: 1,
      },
      {
        currentState: "STATE__2",
        nextState: "STATE__3",
        sequence: 2,
      },
      {
        currentState: "STATE__3",
        nextState: "STATE__4",
        sequence: 3,
      },
      {
        currentState: "STATE___4",
        nextState: "STATE___3",
        sequence: 4,
      },
    ],
  },
  departments: [],
  myRequests: [],
  cb: () => {},
  colors: [],
  activities: [],
} as WorkflowStateProps;
