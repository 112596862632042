import React from "react";
import { StyleSheet, Platform, View } from "react-native";
import { LocalizationContext } from "@lang/context";
import {
  Button,
  Menu,
  Divider,
  Switch,
  Avatar,
  TouchableRipple,
} from "react-native-paper";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { useTheme } from "@react-navigation/native";
import { light, Theme, ThemeContext } from "@theme";
import { AuthStateProps } from "@store/reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import { getData } from "@store/sagas/authSaga";
import { scale, verticalScale } from "react-native-size-matters";
import { Emoji } from "@components/shared";

const TopHeaderRight = () => {
  const { isRTL, locale, setLocale, strings } = React.useContext(
    LocalizationContext
  );
  const dispatch = useDispatch();
  const { authenticated } = useSelector(
    (state: { auth: AuthStateProps }) => state.auth
  );
  const { colors, dark, fonts } = useTheme() as Theme;
  const { toggleTheme } = React.useContext(ThemeContext);
  const [visible, setVisible] = React.useState(false);

  const [isSwitchOn, setIsSwitchOn] = React.useState(dark ? true : false);

  const onToggleSwitch = () => {
    setIsSwitchOn(dark ? false : true);
    toggleTheme(dark ? "light" : "dark");
  };

  const openMenu = () => setVisible(true);

  const closeMenu = () => setVisible(false);
  return (
    <View style={styles.container}>
      <Button
        mode="outlined"
        theme={{ colors, dark, fonts }}
        icon={(props) => (
          <Emoji
            {...props}
            style={{ fontSize: scale(12) }}
            code={locale === "ar" ? "flag-us" : "flag-sa"}
          />
        )}
        style={{
          // borderRadius: scale(20),
          justifyContent: "center",
          ...Platform.select({
            ios: { direction: "rtl" },
            android: { direction: "rtl" },
            web: {},
          }),
        }}
        labelStyle={{ color: colors.text }}
        // icon={(props) => <Icon {...props} name="flag" />}
        onPress={() => setLocale(locale === "ar" ? "en" : "ar")}>
        {locale === "ar" ? strings("shared.en") : strings("shared.ar")}
      </Button>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          ...Platform.select({
            web: { marginHorizontal: scale(10) },
          }),
        }}>
        <Icon
          name="theme-light-dark"
          size={30}
          color={dark ? colors.primary : colors.border}
          onPress={onToggleSwitch}
        />
        <Switch
          // color={dark ? colors.primary : colors.border}
          value={isSwitchOn}
          onValueChange={onToggleSwitch}
          theme={{ colors, dark, fonts }}
        />
      </View>
      {/* () => {
          dispatch(authGetter("Logout")) */}
      {authenticated ? (
        <View
          style={{
            //   paddingTop: 50,
            flexDirection: "row",
            justifyContent: "center",
            // backgroundColor: colors.card,
          }}>
          <Menu
            style={{ marginTop: verticalScale(20) }}
            contentStyle={{ backgroundColor: colors.card }}
            // theme={{ colors, fonts, dark }}
            visible={visible}
            onDismiss={closeMenu}
            anchor={
              <TouchableRipple onPress={openMenu}>
                <Avatar.Image
                  size={44}
                  source={require("@assets/images/icon.png")}
                />
              </TouchableRipple>
            }>
            <Menu.Item
              theme={{ colors, fonts, dark }}
              icon={(props) => <Icon {...props} name="flag" />}
              onPress={() => {}}
              title="Item 1"
            />
            <Menu.Item
              theme={{ colors, fonts, dark }}
              icon={(props) => <Icon {...props} name="flag" />}
              onPress={() => {}}
              title="Item 2"
            />
            <Divider />
            <Menu.Item
              theme={{ colors, dark, fonts }}
              icon={(props) => <Icon {...props} name="flag" />}
              onPress={() => {
                dispatch(getData("Logout"));
              }}
              title={strings("drawer.signout")}
            />
          </Menu>
        </View>
      ) : null}
    </View>
  );
};
export default TopHeaderRight;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginHorizontal: 20,
    minWidth: scale(200),
  },
});
