import { Layout, Separator, Text } from "@components/shared";
import React, { useRef, useState } from "react";
import { StyleSheet, Platform, TouchableOpacity } from "react-native";
import { Picker } from "@react-native-picker/picker";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import * as DocumentPicker from "expo-document-picker";

import {
  ActivityIndicator,
  Button,
  Surface,
  TextInput,
} from "react-native-paper";

import { useLinkTo, useNavigation, useTheme } from "@react-navigation/native";
import { LocalizationContext } from "@lang/context";
import { Theme } from "@theme";
import { moderateScale, scale, verticalScale } from "react-native-size-matters";
import { DepartmentData, WorkflowStateProps } from "@store/reducers/workflow";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "@store/sagas/workflowSaga";
import { AuthStateProps } from "@store/reducers/auth";
import firebase from "firebase";

const NewOrder: React.FC = ({ ...props }) => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();

  const { colors, dark, fonts } = useTheme() as Theme;
  const dispatch = useDispatch();
  const { locale, isRTL, strings, setLocale } = React.useContext(
    LocalizationContext
  );

  /** pull store data */
  const { currentUser } = useSelector(
    (state: { auth: AuthStateProps }) => state.auth
  );

  const {
    workflow: { states, transitions },
    loading,
    departments,
  } = useSelector((state: { workflow: WorkflowStateProps }) => state.workflow);

  /** Department and sections */
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(
    departments.length ? departments[0].id : null
  );

  const [
    selectedDepartment,
    setSelectedDepartment,
  ] = useState<Partial<DepartmentData> | null>(
    departments.length ? departments[0] : null
  );

  const [selectedSectionId, setSelectedSectionId] = useState(
    departments[0] && departments[0].children && departments[0].children.length
      ? departments[0].children[0].id
      : null
  );

  const [
    selectedSection,
    setSelectedSection,
  ] = useState<Partial<DepartmentData> | null>(
    departments[0] && departments[0].children && departments[0].children.length
      ? departments[0].children[0]
      : null
  );

  /** ==================================== */
  const actions = React.useRef(
    states
      .filter((e) => e.id === transitions[0].currentState)
      .map((e) => e.actions)[0]
  ).current;

  /** form data state */
  const [title, setTitle] = useState("");
  const [abstract, setAbstract] = useState("");
  const [attachmentURL, setAttachmentURL] = useState("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [percent, setPercent] = useState(0);

  const [selectedAction, setSelectedAction] = useState(actions[0].id);

  const onFileUpload = async () => {
    const resp: any = await DocumentPicker.getDocumentAsync({
      type: "application/pdf",
    });

    if (resp.type !== "success") {
      // errorHandler("cancelled ..");
      return setIsUploading(false);
    }
    const reference = firebase
      .storage()
      .ref(`${currentUser?.uid}_article_${new Date().getTime()}.pdf`);
    // path to existing file on filesystem
    setIsUploading(true);

    var mimeString = resp.uri.split(",")[0].split(":")[1].split(";")[0];

    // uploads file
    const task = reference.putString(resp.uri, "data_url", {
      contentType: mimeString,
    });
    task.on(firebase.storage.TaskEvent.STATE_CHANGED, (taskSnapshot) => {
      setPercent(
        (taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100
      );
    });

    task.then(async () => {
      const url = await reference.getDownloadURL();
      setIsUploading(false);
      setAttachmentURL(url);
      //   setImage(url);
    });

    task.catch((err) => {
      // hideAlert();
      return setIsUploading(false);
    });
  };
  const submitRequest = () => {
    const action = actions.find((e) => e.id === selectedAction);
    const state = states.find((e) => e.id === transitions[0].nextState);
    let department: any = null;
    if (selectedSectionId) {
      department = selectedSection;
    } else {
      department = selectedDepartment;
    }
    dispatch(setData({ loading: true }, "SetState"));
    dispatch(
      setData(
        {
          requesPayload: {
            action,
            state,
            main_department: {
              id: selectedDepartment?.id,
              name_ar: selectedDepartment?.name_ar,
              name_en: selectedDepartment?.name_en,
            },
            department,
            title,
            abstract,
            user: currentUser,
            reviewers: [],
            last_note: "",
            file: attachmentURL,
            isActive: true,
          },
          cb: () => linkTo("/my_requests"),
        },
        "AddRequest"
      )
    );
  };

  return (
    <Layout>
      <Surface theme={{ colors, dark, fonts }} style={styles.surface}>
        <Picker
          mode="dropdown"
          selectedValue={selectedDepartmentId}
          accessibilityRole="combobox"
          style={{
            height: 50,
            backgroundColor: colors.background,
            borderColor: colors.placeholder,
            color: colors.text,
            paddingHorizontal: scale(5),
            marginBottom: 10,
            paddingVertical: 15,
            ...fonts?.medium,
            fontSize: 18,
          }}
          itemStyle={{
            color: colors.text,
            backgroundColor: colors.background,
            ...fonts?.medium,
          }}
          onValueChange={(itemValue, itemIndex) => {
            setSelectedDepartmentId(itemValue);
            setSelectedDepartment(
              departments.find((e) => e.id === itemValue) as DepartmentData
            );
          }}>
          {departments.map((e, i) => (
            <Picker.Item
              key={`DEPT-${i}`}
              label={eval(`e.name_${locale}`)}
              value={e.id}
              color={colors.text}
              fontFamily={fonts?.medium.fontFamily}
            />
          ))}
        </Picker>
        {selectedDepartment?.children && selectedDepartment.children.length ? (
          <Picker
            mode="dropdown"
            selectedValue={selectedSectionId}
            accessibilityRole="combobox"
            style={{
              height: 50,
              backgroundColor: colors.background,
              borderColor: colors.placeholder,
              color: colors.text,
              paddingHorizontal: scale(5),
              marginBottom: 10,
              paddingVertical: 15,
              ...fonts?.medium,
              fontSize: 18,
            }}
            itemStyle={{
              color: colors.text,
              backgroundColor: colors.background,
              ...fonts?.medium,
            }}
            onValueChange={(itemValue, itemIndex) => {
              setSelectedSectionId(itemValue);
              setSelectedSection(
                selectedDepartment.children!.find(
                  (e) => e.id === itemValue
                ) as DepartmentData
              );
            }}>
            {selectedDepartment.children.map((e, i) => (
              <Picker.Item
                key={`SECTION-${i}`}
                label={eval(`e.name_${locale}`)}
                value={e.id}
                color={colors.text}
                fontFamily={fonts?.medium.fontFamily}
              />
            ))}
          </Picker>
        ) : null}
        <TextInput
          selectionColor={colors.placeholder}
          style={styles.input}
          mode="outlined"
          label="Research Title"
          placeholder="ahmed@ceba.edu"
          onChangeText={setTitle}
          theme={{ colors, dark, fonts }}
          placeholderTextColor={colors.placeholder}
        />
        <TextInput
          selectionColor={colors.placeholder}
          style={[styles.input, { height: verticalScale(80) }]}
          multiline
          mode="outlined"
          label="Abstract"
          placeholder="ahmed@ceba.edu"
          onChangeText={setAbstract}
          theme={{ colors, dark, fonts }}
          placeholderTextColor={colors.placeholder}
        />
        <Picker
          mode="dropdown"
          selectedValue={selectedAction}
          accessibilityRole="combobox"
          style={{
            height: 50,
            backgroundColor: colors.background,
            borderColor: colors.placeholder,
            color: colors.text,
            paddingHorizontal: scale(5),
            marginBottom: 10,
            ...fonts?.medium,
            fontSize: 18,
          }}
          itemStyle={{
            color: colors.text,
            backgroundColor: colors.background,
            ...fonts?.medium,
            paddingVertical: 5,
          }}
          onValueChange={(itemValue, itemIndex) => {
            console.log("val:", itemValue);

            setSelectedAction(itemValue);
          }}>
          {states
            .filter((e) => e.id === transitions[0].currentState)
            .map((e) => e.actions)[0]
            .map((e, i) => (
              <Picker.Item
                key={`ACTION----${i}`}
                label={eval(`e.name${isRTL ? "_ar" : "en"}`)}
                value={e.id}
                color={colors.text}
                fontFamily={fonts?.medium.fontFamily}
              />
            ))}
        </Picker>

        <TouchableOpacity
          disabled={isUploading}
          onPress={onFileUpload}
          style={[styles.addImageContainer, { borderColor: colors.primary }]}>
          {isUploading ? (
            <>
              <ActivityIndicator theme={{ colors, dark, fonts }} />
              <Separator
                style={{
                  marginVertical: moderateScale(15),
                  backgroundColor: "transparent",
                }}
                title={`Uploading file: ${percent.toFixed(0)}%`}
              />
            </>
          ) : (
            <Icon name="camera-plus" size={scale(20)} color={colors.primary} />
          )}
        </TouchableOpacity>

        <Button
          loading={loading}
          mode="contained"
          theme={{ colors, dark, fonts }}
          style={{
            ...Platform.select({
              web: { marginVertical: 20 },
            }),
          }}
          icon={(props) => <Icon {...props} name="send" />}
          onPress={submitRequest}>
          {strings("auth.signup")}
        </Button>
      </Surface>
    </Layout>
  );
};

export const NewOrderScreen = React.memo(NewOrder);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  surface: {
    marginVertical: scale(20),
    paddingVertical: scale(20),
    marginHorizontal: scale(20),
    paddingHorizontal: scale(20),
    alignSelf: "center",
    // alignItems: "center",
    minWidth: 800,
    // flexDirection: "row",
  },
  input: {
    // marginVertical: verticalScale(10),
    // marginHorizontal: scale(20),
    textAlign: "left",
    ...Platform.select({
      web: {
        marginVertical: 10,
      },
    }),
  },
  addImageContainer: {
    justifyContent: "center",
    alignItems: "center",
    // margin: moderateScale(10),
    height: verticalScale(60),
    borderWidth: scale(1),
    borderStyle: "dashed",
  },
});
