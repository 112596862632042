// import {showMessage, hideMessage} from "react-native-flash-message";

export enum ERRORS {
  NOT_VALID_CODE = "errors.code",
  LOGIN_ERROR = "errors.login",
  ALREADY_LOGGED_OUT = "errors.already_loggedout",
}

// export const hideAlert = () => hideMessage();
export const errorHandler = (
  message: string,
  type?: "success" | "info" | "warning" | "danger" | "default",
  autoHide?: boolean,
  title?: string,
  duration?: number
) => {
  // showMessage({
  //   // description: strings(message),
  //   message,
  //   type,
  //   // onPress: () => {
  //   //   /* THIS FUNC/CB WILL BE CALLED AFTER MESSAGE PRESS */
  //   // },
  //   duration: duration ? duration : 5000,
  //   autoHide: autoHide === undefined ? true : autoHide,
  // });
};
