import { Text } from "@components/shared";
import { LocalizationContext } from "@lang/context";
import { useTheme } from "@react-navigation/native";
import { Theme } from "@theme";
import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { Surface } from "react-native-paper";
import { scale } from "react-native-size-matters";
import Svg, { Circle, Rect } from "react-native-svg";

const RequestSkeletonComponent = () => {
  const { colors, fonts, dark } = useTheme() as Theme;
  const { isRTL } = useContext(LocalizationContext);
  return (
    <View style={{ marginVertical: scale(3) }}>
      {Array(10)
        .fill(1)
        .map((e, i) => (
          <Surface theme={{ colors, dark, fonts }} style={styles.container}>
            {isRTL ? (
              <Svg width={"100%"} height="100">
                <Rect
                  rx={5}
                  ry={5}
                  x="49%"
                  y="15"
                  width={"50%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
                <Rect
                  rx={5}
                  ry={5}
                  x="19%"
                  y="40"
                  width={"80%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
                <Rect
                  rx={5}
                  ry={5}
                  x="79%"
                  y="65"
                  width={"20%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
              </Svg>
            ) : (
              <Svg width={"100%"} height="100">
                <Rect
                  rx={5}
                  ry={5}
                  x="15"
                  y="15"
                  width={"50%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
                <Rect
                  rx={5}
                  ry={5}
                  x="15"
                  y="40"
                  width={"80%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
                <Rect
                  rx={5}
                  ry={5}
                  x="15"
                  y="65"
                  width={"20%"}
                  height="15"
                  fill={dark ? colors.placeholder : colors.border}
                  //   strokeWidth="3"
                  //   stroke="rgb(0,0,0)"
                />
              </Svg>
            )}
          </Surface>
        ))}
    </View>
  );
};

export const RequestSkeleton = React.memo(RequestSkeletonComponent);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: scale(3),
    marginHorizontal: scale(20),
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
  },
});
