import { NavigationContainer, useTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";

import NotFoundScreen from "../screens/NotFoundScreen";
import { RootStackParamList } from "./types";
import LinkingConfiguration from "./LinkingConfiguration";

import { dark as DarkTheme, light as DefaultTheme, Theme } from "@theme";
import DrawerNavigator from "./DrawerNavigator";
import { Login } from "@screens";
import { screenWithHeader } from "./options";
import { LocalizationContext } from "@lang/context";

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator({ authenticated }: { authenticated: boolean }) {
  const { strings } = React.useContext(LocalizationContext);
  const { colors, dark } = useTheme() as Theme;
  return (
    <Stack.Navigator screenOptions={{ cardStyle: { flex: 1 } }}>
      {!authenticated ? (
        <Stack.Screen
          name="Login"
          component={Login}
          options={(props) =>
            screenWithHeader(
              {
                ...props,
                headerStyle: {
                  borderBottomWidth: 1,
                  borderBottomColor: colors.background,
                },
              },
              strings("auth.signin")
            )
          }
        />
      ) : (
        <Stack.Screen
          name="Root"
          options={{ headerShown: false, headerStyle: { direction: "ltr" } }}
          component={DrawerNavigator}
        />
      )}
      <Stack.Screen
        name="NotFound"
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </Stack.Navigator>
  );
}

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
  authenticated,
  locale,
}: {
  colorScheme: ColorSchemeName;
  authenticated: boolean;
  locale: string;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}>
      <RootNavigator {...{ authenticated }} />
    </NavigationContainer>
  );
}
