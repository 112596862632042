import { AuthStateProps } from "./interfaces";

export default {
  loading: false,
  currentUser: undefined,
  confirmationResult: undefined,
  authenticated: false,
  selectedCountry: { dialCode: "+966", code: "sa", name: "Saudi Arabia" },
  email: undefined,
  isNewUser: false,
  userOnHold: undefined,
} as AuthStateProps;
