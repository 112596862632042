import React from "react";
import { View } from "react-native";
import { HeaderBackButton } from "@react-navigation/stack";
import { AntDesign as Icon } from "@expo/vector-icons";
import { scale, moderateScale } from "react-native-size-matters";
import { useTheme, useNavigation } from "@react-navigation/native";
import { Theme } from "@theme";
import { LocalizationContext } from "@lang/context";

const CBackButton: React.FC<any> = (props) => {
  const navigation = useNavigation();
  const { colors } = useTheme() as Theme;
  const { isRTL } = React.useContext(LocalizationContext);

  const canGoBack = navigation.dangerouslyGetState().index;
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <View>
      {canGoBack ? (
        <HeaderBackButton
          style={{ width: scale(50) }}
          labelVisible={false}
          labelStyle={{ color: colors.text }}
          backImage={(props) => (
            <Icon
              name={isRTL ? "arrowright" : "arrowleft"}
              // name={"arrowleft"}
              style={{
                color: colors.text,
                marginHorizontal: scale(10),
                // direction: isRTL ? "ltr" : "rtl",
              }}
              size={moderateScale(24)}
            />
          )}
          onPress={goBack}
          tintColor={colors.primary}
        />
      ) : null}
    </View>
  );
};

export const WithHeaderBackButton = React.memo(CBackButton);
