import React from "react";
import I18n from "./i18n";
import dayjs from "dayjs";

import { I18nManager } from "react-native";

interface LocalizationContextType {
  locale: string;
  strings: (...args: any) => any;
  setLocale: (...args: any) => any;
  isRTL: boolean;
}

const initialValue: LocalizationContextType = {
  strings: (name: string, params = {}) => {
    return I18n.t(name, params);
  },
  locale: "ar",
  isRTL: true,
  setLocale: () => {},
};

export const LocalizationContext: React.Context<LocalizationContextType> = React.createContext(
  initialValue
);

// return the LocalizationProvider as a wrapper
export const LocalizationProvider = ({
  children,
}: JSX.ElementChildrenAttribute): React.ReactElement => {
  const [locale, setLocale] = React.useState("ar");
  const [isRTL, setIsRTL] = React.useState<boolean>(true);
  React.useEffect(() => {
    changeLanguage("ar");
    return () => {
      // cleanup
    };
  }, []);
  const changeLanguage = (locale: string) => {
    if (locale == "ar") {
      require("dayjs/locale/ar");
      dayjs.locale("ar");
      I18nManager.forceRTL(true);
      setIsRTL(true);
    } else {
      require("dayjs/locale/ar");
      dayjs.locale("en");
      I18nManager.forceRTL(false);
      setIsRTL(false);
    }
    I18n.locale = locale;
    setLocale(locale);
  };
  const localizationContext = React.useMemo(
    () => ({
      strings: (name: string, params = {}) => {
        return I18n.t(name, params);
      },
      locale,
      setLocale: (locale: "ar" | "en") => changeLanguage(locale),
      isRTL,
    }),
    [locale]
  );
  return (
    <LocalizationContext.Provider value={localizationContext}>
      {children}
    </LocalizationContext.Provider>
  );
};
