import * as React from "react";
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  View,
  Text,
} from "react-native";

import { Theme } from "@theme";
import { Link, useLinkTo, useRoute, useTheme } from "@react-navigation/native";
import { LocalizationContext } from "@lang/context";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { scale, verticalScale } from "react-native-size-matters";
import screenfull from "screenfull";

const links = [
  {
    locale: "dashboard",
    to: "/dashboard",
    icon: "poll",
    name: "DashboardScreen",
  },
  { locale: "new_order", to: "/new", icon: "plus", name: "NewOrderScreen" },
  // {
  //   locale: "track_order",
  //   to: "/request/xx",
  //   icon: "radar",
  //   name: "RequestScreen",
  // },
  {
    locale: "orders",
    to: "/my_requests",
    icon: "form-dropdown",
    name: "MyOrdersScreen",
  },
  {
    locale: "dashboard",
    to: "/dashboard",
    icon: "poll",
    name: "DashboardScreen",
  },
  { locale: "new_order", to: "/new", icon: "plus", name: "NewOrderScreen" },
];

interface Props {
  children: React.ReactNode | React.ReactNode[];
  left?: () => React.ReactElement<any>;
}
const LayoutComponent: React.FC<Props> = ({ children, ...props }) => {
  const linkTo = useLinkTo();
  const route = useRoute();

  const { colors, dark, fonts } = useTheme() as Theme;
  const { locale, isRTL, strings, setLocale } = React.useContext(
    LocalizationContext
  );

  const getFullScreenNode = () => document.documentElement || document.body;

  const renderLeftElement = (): React.ReactElement<any> => {
    const leftElement: React.ReactElement<any> =
      typeof props.left !== "string" ? (
        props.left!()
      ) : (
        <Text>{props.left}</Text>
      );

    return React.cloneElement(leftElement, {
      style: [leftElement.props.style],
    });
  };

  const onPress = async (to: string) => {
    linkTo(to);
    // if (screenfull.isEnabled) {
    //   screenfull.request(getFullScreenNode());
    // }
  };

  return (
    <View style={styles.container}>
      {/* <View style={[styles.sideBar, { backgroundColor: colors.card }]}> */}
      <ScrollView
        contentContainerStyle={[
          styles.sideBarLeft,
          { backgroundColor: colors.card },
        ]}>
        {links.map((e, i) => (
          <TouchableOpacity
            accessibilityRole="link"
            onPress={() => onPress(e.to)}
            style={[
              styles.row,
              {
                backgroundColor: colors.background,
              },
            ]}>
            <Icon
              name={e.icon as any}
              size={24}
              color={
                route.name === e.name
                  ? dark
                    ? colors.primary
                    : colors.secondary
                  : colors.text
              }
              // style={{ marginTop: -5 }}
            />
            <Text
              // to={e.to}
              style={[
                styles.link,
                {
                  color:
                    route.name === e.name
                      ? dark
                        ? colors.primary
                        : colors.secondary
                      : colors.text,
                  ...fonts?.bold,
                  textAlign: "center",
                },
              ]}>
              {strings(`menu.${e.locale}`)}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
      {/* </View> */}
      <View style={[styles.content, { backgroundColor: colors.background }]}>
        <ScrollView>{children}</ScrollView>
      </View>
      {props.left ? (
        <ScrollView
          contentContainerStyle={[
            styles.sideBarRight,
            { backgroundColor: colors.card },
          ]}>
          {renderLeftElement()}
        </ScrollView>
      ) : null}
    </View>
  );
};

export const Layout = React.memo(LayoutComponent);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  sideBarRight: { flex: 2.5, backgroundColor: "cyan" },
  content: { flex: 7 },
  sideBarLeft: { flex: 2.5, backgroundColor: "cyan" },

  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  link: {
    fontSize: 15,
    paddingVertical: verticalScale(5),
  },
  row: {
    marginHorizontal: scale(10),
    marginVertical: verticalScale(5),
    // flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(10),
    // width: scale(50),
    // paddingHorizontal: scale(10),
    height: verticalScale(40),
    // alignSelf: "center",
  },
});
