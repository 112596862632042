import React from "react";
import { WithHeaderBackButton } from "./WithHeaderBackButton";
import { Image, StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { Theme } from "@theme";

import TopHeaderRight from "./TopHeaderRight";
import { TopHeaderLeft } from "./TopHeaderLeft";
import { scale } from "react-native-size-matters";
import { Searchbar } from "react-native-paper";

/**
 * render screen without header bar.
 */
export const screenWithoutHeader = (props?: any) => ({
  headerShown: false,
  ...props,
});
/**
 * render generic route screen with a header bar
 * @param props
 * @param title
 */
export const screenWithHeader = (props: any, title?: string) => {
  return {
    ...props,
    title,
    headerTitle: (props: any) => {
      // const { isRTL, locale, setLocale, strings } = React.useContext(
      //   LocalizationContext
      // );
      const [searchText, setSearchText] = React.useState("");
      const { colors, dark, fonts } = useTheme() as Theme;
      return (
        <View style={styles.titleContainer}>
          {/* <Text
            style={[styles.title, { color: colors.primary, ...fonts?.bold }]}>
            {title}
          </Text> */}
          <Searchbar
            theme={{ colors, dark, fonts }}
            style={{
              flex: 1,
              backgroundColor: colors.background,
              shadowColor: colors.background,
            }}
            value={searchText}
            onChangeText={setSearchText}
            icon={(props) => <Icon {...props} name="search-web" />}
            clearIcon={(props) =>
              !!!searchText ? null : <Icon {...props} name="close" />
            }
          />
        </View>
      );
    },
    headerLeft: () => <TopHeaderLeft />,
    headerRight: () => <TopHeaderRight />,
    // headerBackTitle: () => <WithHeaderBackButton />,s
  };
};
const styles = StyleSheet.create({
  logo: {
    height: scale(109) * (109 / 500),
    width: scale(109),
  },
  titleContainer: {
    justifyContent: "center",
    alignItems: "center",
    // minWidth: scale(200),
    alignSelf: "center",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
  },
});
