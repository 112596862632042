import React, {memo} from "react";
import {Text, TextProps} from "react-native";
import nodeEmoji from "node-emoji";

interface IProps extends TextProps {
  code: string;
}
const Emoji_: React.FC<IProps> = ({code, ...restProps}) => {
  if (nodeEmoji.hasEmoji(code)) {
    const emoji = nodeEmoji.get(code);
    return <Text {...restProps}>{emoji}</Text>;
  } else {
    const emoji = nodeEmoji.get("globe_with_meridians");
    return <Text {...restProps}>{emoji}</Text>;
  }
};

export const Emoji = React.memo(Emoji_);
