import initialState from "./initialState";
import {Action, ActionType, AuthStateProps} from "./interfaces";
import produce from "immer";
/**
 * react services reducers for state management
 */
const reducers = (
  state = initialState,
  {type, payload}: Action<ActionType, any>,
): AuthStateProps =>
  produce(state, (draftState) => {
    switch (type) {
      // update any state data
      case "SetAuth":
        return {
          ...draftState,
          ...payload,
        };
      // update currentuser
      case "UpdateCurrentUser":
        return {
          ...draftState,
          currentUser: {...draftState.currentUser, ...payload.currentUser},
        };
      // clear state
      case "ResetState": {
        return initialState;
      }
      // return the state is no action or invalid action type has been sent to the reducer
      default:
        return draftState;
    }
  });

export default reducers;
