import { AntDesign } from "@expo/vector-icons";
import { Asset } from "expo-asset";
import { Image, ImageRequireSource } from "react-native";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as FirebaseCore from "expo-firebase-core";
import * as React from "react";
import firebase from "firebase";

import {
  WEB_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_MSG_SENDER_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_STORAGE_BUCKET,
} from "@env";

export interface Assets {
  images: ImageRequireSource[];
  fonts: { [key: string]: number } | any;
}

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  const assets: Assets = {
    images: [
      require("@assets/images/logo-light.png"),
      require("@assets/images/logo-dark.png"),
    ],
    fonts: {
      ...AntDesign.font,
      "space-mono": require("@assets/fonts/SpaceMono-Regular.ttf"),
      "Tajawal-Bold": require("@assets/fonts/Tajawal-Bold.ttf"),
      "Tajawal-Light": require("@assets/fonts/Tajawal-Light.ttf"),
      "Tajawal-Medium": require("@assets/fonts/Tajawal-Medium.ttf"),
      "Tajawal-Regular": require("@assets/fonts/Tajawal-Regular.ttf"),
    },
  };
  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();
        await firebase.initializeApp({
          apiKey: WEB_API_KEY,
          authDomain: FIREBASE_AUTH_DOMAIN,
          projectId: FIREBASE_PROJECT_ID,
          storageBucket: FIREBASE_STORAGE_BUCKET,
          messagingSenderId: FIREBASE_MSG_SENDER_ID,
          appId: FIREBASE_APP_ID,
        });
        // Load fonts
        await Font.loadAsync({
          ...assets.fonts,
        });
        await assets.images.map((image) => {
          if (typeof image === "string") {
            return Image.prefetch(image);
          } else {
            return Asset.fromModule(image).downloadAsync();
          }
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
