import { StatusBar } from "expo-status-bar";
import React, { useContext, useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  Provider as StoreProvider,
  useDispatch,
  useSelector,
} from "react-redux";
import { Provider as PaperProvider } from "react-native-paper";

import { ThemeContext, ThemeProvider } from "@theme";
import configureStore from "@store";
import useCachedResources from "@hooks/useCachedResources";
import * as Localization from "expo-localization";
import { Router } from "@navigations";
import { AuthStateProps } from "@store/reducers/auth";
import { LocalizationContext, LocalizationProvider } from "@lang/context";
import { setData } from "@store/sagas/authSaga";
import firebase from "firebase";
import {
  getData as workflowGetter,
  setData as workflowSetter,
} from "@store/sagas/workflowSaga";
import initialState from "@store/reducers/workflow/initialState";

const App: React.FC<any> = () => {
  const dispatch = useDispatch();
  const { locale, isRTL } = React.useContext(LocalizationContext);

  const isLoadingComplete = useCachedResources();
  const { theme } = useContext(ThemeContext);
  const { dark, colors } = theme;
  const { authenticated } = useSelector(
    (state: { auth: AuthStateProps }) => state.auth
  );

  const onAuthStateChanged = async (user: firebase.User | any) => {
    if (user) {
      const token = await user.getIdToken();
      console.log(token);

      dispatch(
        setData(
          {
            currentUser: {
              name: user.displayName!,
              email: user.email!,
              uid: user.uid,
              profile_image: user.photoURL,
            },
            authenticated: true,
          },
          "SetAuth"
        )
      );
    } else {
      dispatch(
        setData(
          {
            currentUser: undefined,
            loading: false,
            authenticated: false,
          },
          "SetAuth"
        )
      );
    }
  };
  useEffect(() => {
    dispatch(workflowGetter("LoadDepartments"));
    // dispatch(workflowSetter({ workflow: initialState.workflow }, "SetState"));
    const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider style={{ direction: isRTL ? "rtl" : "ltr" }}>
        <Router
          colorScheme={dark ? "dark" : "light"}
          {...{ authenticated }}
          {...{ locale }}
        />
        <StatusBar />
      </SafeAreaProvider>
    );
  }
};

const { store, persistor } = configureStore();
const AppContainer: React.FC<any> = () => {
  return (
    <LocalizationProvider>
      <ThemeProvider>
        <StoreProvider store={store}>
          <PaperProvider>
            <App />
          </PaperProvider>
        </StoreProvider>
      </ThemeProvider>
    </LocalizationProvider>
    /* </AssetsLoader> */
    //     </ActionSheetProvider>
  );
};

export default AppContainer;
