export default {
  shared: {
    morning: "morning",
    afternoon: "afternoon",
    evening: "evening",
    // login: "Sign In",
    login: "Sign In",
    no_result: "No data found.",
    back: "Back",
    app_name: "Ittafugna",
    en: "English",
    ar: "العربية",
    refresh: "Refresh",
  },
  menu: {
    home: "Home",
    Journal_list: "Articles",
    support: "Support",
    guide: "Guide for Authors",
    about: "About",
    dashboard: "Dashboard",
    new_order: "New Order",
    track_order: "Track Your Order",
    orders: "Orders List",
  },
  // general: {
  // },
  tabbar: {
    title: "Contractors",
  },
  request: {
    author: "Author",
    new_order: "New order",
    back: "Back",
    status: "Status",
    active: "Active",
    inactive: "Closed",
    filter_title: "Filter the list",
    file: "Open Attachment",
    note: "Latest Note",
  },
  auth: {
    name: "Name",
    phone: "Phone",
    previous: "Previous",
    next: "Next",
    step1: "General",
    step1_msg: "Enter your personal info to help you interact wiht others",
    step2: "services",
    step2_msg: "select which professions of the following most describe you",
    step3: "location",
    step3_msg: "Where are you located?",
    step4: "Verify",
    step4_msg: "Enter the verification code sent to your phone",
    register: "Sign Up",
    yes: "Yes",
    no: "No",
    is_pro_msg: "Do you want to sign up as a service provider?",
    service_option_msg: "Sign up as:",
    company: "Company",
    individual: "Individual",
    resend_code: "Resend SMS Code",
    no_code: "Haven't received SMS code?",
    default_location: "Riyadh City",
    select_region: "Select Region/State",
    select_city: "Select City",
    select_district: "Select District",
    picker_default_txt: "Select Item",
    loading: "loading...",
    search_placeholder: "search ...",
    search_error: "Not Found",
    signin_msg: "Please enter your phone number to sign in",
    signin: "Sign In",
    returning_user: "Have an account?",
    signup: "Sign Up",
    no_account: "Don't have an account?",
    agreement_note_1: "by signing up you agree to our",
    terms_and_conditions: "Terms & Conditions",
    agreement_note_2: "and our",
    privacy_policy: "Privacy Policy",
    agreement_note_3: " &",
    eula: "EULA",
  },
  errors: {
    code: "Invalid code! Please try again or click resend",
    login: "invalid login. Please try again!",
    already_loggedout: "You are already logged out!",
    invalid_phone: "Must be valid phone number",
    invalid_name: "Invalid name (3-30 characters)",
  },
  user: {
    call: "Call",
    chat: "Live chat",
    portfolio: "Portfolio",
    rating: "Rating",
    apply_filter: "Apply Filter",
    cancel_filter: "Cancel",
    filter_title: "Filter the list",
    all_cities: "All Cities",
    all_districts: "All Districts",
    sort_asc: "Sort By Lowest",
    sort_desc: "Sort By Greatest",
    sort_date: "Sort By Date",
    add_review_placeholder: "Add new review ...",
    total_ratings: "Total Ratings",
    review_show_more: "...more",
    send_review: "Send Review",
    terrible: "Terrible 😞",
    bad: "Bad 😏",
    ok: "OK 👍",
    very_good: "Very Good 😚",
    wow: "WOW 😍",
    no_review_body: "There is no comment",
    signin: "Sign In",
    phone: "Phone",
    in_app_call: "In-App Call",
    close: "Close",
    edit_profile: "My Profile",
    update_profile: "Update Profile",
    service_provider: "Service Provider",
    normal_user: "Normal User",
    user_IAM: "Registered as:",
    service_type: "Service Type:",
    profile_updated_success: "Yay! You profile's been updated successfuly",
    added_fav: "Added to favourite list",
    removed_fav: "removed from favourite list",
    exceed_fav: "You have exceeded your favourite list limit",
    delete_file: "Delete Image",
    delete_file_alert:
      "This file will be permenantly deleted and you will be unable to restore it later. Are you sure you want to delete it?",
    delete_file_alert_yes: "Yes. Delete it!",
    delete_file_alert_cancel: "Cancel",
  },
  drawer: {
    home: "Home",
    bookmarks: "Bookmarks",
    privacy: "Privacy Policy",
    terms: "Terms & Conditions",
    support: "Support",
    edit_profile: "Edit Profile",
    signout: "Signout",
    signin: "Sign In",
    signup: "Sign Up",
    rating: "Rating",
    returning_user: "Have an account?",
    new_user: "New user?",
    dark_theme: "Dark Theme",
    signout_alert: "Are you sure you want to logout?",
    signout_alert_yes: "Yes! I am sure.",
    signout_alert_cancel: "Cancel",
    portfolio: "My Portfolio",
    admin_unavailable: "All support agents are unavailable!",
    contacting_admin: "Getting in touch...",
  },
  chat: {
    send: "Send",
    send_input_placeholder: "Write a message...",
    loading_earlier: "View earlier messages",
    send_location: "Send My Location",
    my_location: "My Location 🌏",
    my_chat: "My Chats",
  },
  location: {
    permission_denied_title: "Cannot be send {{permissionName}} 😞",
    permission_denied_msg: `If you would like to use this feature, you'll need to enable the {{permissionName}} permission in your phone settings.`,
    permission_denied_ok_action: "Let's go!",
    permission_denied_cancel_action: "Nevermind",
  },
};
