import firebase from "firebase";
import axios from "axios";

const API = "https://us-central1-baha-journal.cloudfunctions.net/api";
// generic fetch from our firebase functions api
export const get = async (uri: string, requireAuth?: boolean) => {
  if (uri.includes("https:")) {
    deleteAuthHeader();
    const { data }: any = await axios.get(uri);
    return data;
  } else {
    await setRequestHeader(requireAuth!);
  }
  try {
    const { data } = await axios.get(`${API}/${uri}`);
    return data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      const errorText: string = error.response.data.errors[0].message;
      // messageAlert("", errorText);
      throw errorText;
    } else {
      // console.error(error);
      throw error.message ? error.message : error;
    }
  }
};

// generic post action to our firebase functions api
export const post = async (
  uri: string,
  body?: object,
  requireAuth?: boolean
): Promise<object> => {
  await setRequestHeader(requireAuth!);
  try {
    const { data } = await axios.post(`${API}/${uri}`, body);
    return data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      const errorText: string = error.response.data.errors[0].message;
      const param = error.response.data.errors[0].field!;
      throw param ? param + ": " + errorText.toLowerCase() : errorText;
    } else {
      throw error.message ? error.message : error;
    }
  }
};
/**
 * delete request
 */
export const remove = async (uri: string, requireAuth?: boolean) => {
  await setRequestHeader(requireAuth!);
  try {
    const { data } = await axios.delete(`${API}/${uri}`);
    return data;
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.errors
    ) {
      const errorText: string = error.response.data.errors[0].message;
      // messageAlert("", errorText);
      throw errorText;
    } else {
      // console.error(error);
      throw error.message ? error.message : error;
    }
  }
};
/**
 * delete axios header that includes Authorization
 */
const deleteAuthHeader = (): boolean =>
  delete axios.defaults.headers.common["Authorization"];

/**
 * get token
 */

const setRequestHeader = async (requireAuth: boolean) => {
  if (requireAuth) {
    const user = await firebase.auth().currentUser;
    const token = await user?.getIdToken();
    if (token) {
      return (axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`);
    } else {
      return deleteAuthHeader();
    }
  } else {
    return deleteAuthHeader();
  }
};
