import { LocalizationContext } from "@lang/context";
import { Link, useRoute, useTheme } from "@react-navigation/native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { Theme } from "@theme";
import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { moderateScale, scale } from "react-native-size-matters";
import { WithHeaderBackButton } from "./WithHeaderBackButton";

const leftLinks = [
  // { locale: "home", to: "/login", icon: "home" },
  { locale: "Journal_list", to: "/one", icon: "home" },
  // { locale: "support", to: "/two", icon: "home" },
  { locale: "guide", to: "/one", icon: "home" },
  { locale: "about", to: "/about", icon: "home" },
];
interface Props {}
const TopLeftHeaderComponent: React.FC<Props> = (props) => {
  const route = useRoute();
  console.log(route);

  const { strings } = React.useContext(LocalizationContext);
  const { colors, dark, fonts } = useTheme() as Theme;
  return (
    <View style={styles.container}>
      <WithHeaderBackButton />
      {leftLinks.map((e, i) => (
        <View
          style={[
            styles.row,
            i === 0 ? { paddingHorizontal: 0, paddingRight: scale(5) } : {},
          ]}>
          <Icon
            name={e.icon as any}
            size={14}
            color={colors.text}
            style={{ marginTop: -5 }}
          />
          <Link
            style={[styles.link, { color: colors.text, ...fonts?.bold }]}
            to={e.to}>
            {strings(`menu.${e.locale}`)}
          </Link>
        </View>
      ))}
    </View>
  );
};

export const TopHeaderLeft = React.memo(TopLeftHeaderComponent);

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginHorizontal: 20,
    minWidth: scale(200),
    // display: "none",
  },
  link: {
    fontSize: 15,
  },
  row: {
    paddingHorizontal: scale(5),
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
