import * as React from "react";
import { StyleSheet, Text } from "react-native";

import { useTheme } from "@react-navigation/native";
import { LocalizationContext } from "@lang/context";
import { Theme } from "@theme";
import { scale, verticalScale } from "react-native-size-matters";
import { Layout } from "@components/shared";

const Dashboard: React.FC = ({ ...props }) => {
  const { colors, dark, fonts } = useTheme() as Theme;
  const { locale, isRTL, strings, setLocale } = React.useContext(
    LocalizationContext
  );
  return (
    <Layout>
      <Text style={styles.title}>Dashboard</Text>
    </Layout>
  );
};

export const DashboardScreen = React.memo(Dashboard);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
  sideBar: { flex: 2.5, backgroundColor: "cyan" },
  content: { flex: 9.5 },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
  link: {
    fontSize: 15,
    paddingVertical: verticalScale(5),
  },
  row: {
    marginHorizontal: scale(10),
    marginVertical: verticalScale(5),
    // flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: scale(10),
    // width: scale(50),
    // paddingHorizontal: scale(10),
    height: verticalScale(40),
    // alignSelf: "center",
  },
});
