import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import { RequestItem, RequestSkeleton } from "@components";
import { Layout, Text } from "@components/shared";
import { LocalizationContext } from "@lang/context";
import { useTheme } from "@react-navigation/native";
import { WorkflowStateProps, RequestData } from "@store/reducers/workflow";
import { getData, setData } from "@store/sagas/workflowSaga";

import { Theme } from "@theme";
import React from "react";
import { StyleSheet, View, TouchableOpacity } from "react-native";

import { scale, verticalScale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import { Chip } from "react-native-paper";

const MyOrders = () => {
  const { colors, dark, fonts } = useTheme() as Theme;
  const dispatch = useDispatch();
  const { locale, isRTL, strings, setLocale } = React.useContext(
    LocalizationContext
  );

  const [requests, setRequests] = React.useState<Partial<RequestData>[]>([]);
  const [selectedColors, setSelectedColors] = React.useState<string[]>([]);
  const { myRequests, loading, colors: stateColors } = useSelector(
    (state: { workflow: WorkflowStateProps }) => state.workflow
  );

  const [active, setOnlyActive] = React.useState<boolean>(true);
  const [inactive, setOnlyInactive] = React.useState<boolean>(true);

  const loadRequests = () => {
    dispatch(setData({ loading: true }, "SetState"));
    dispatch(getData("LoadMyRequests"));
  };

  const onFilterPress = (state: string) => {
    const exists = selectedColors.find((c: string) => c === state);
    if (exists) {
      setSelectedColors((prev) => prev.filter((c: string) => c !== state));
      setRequests((prev) => prev.filter((e) => e.action?.color !== state));
    } else {
      setSelectedColors((prev) => [...prev, state]);
      setRequests((prev) => [
        ...prev,
        ...myRequests.filter((e) => e.action?.color === state),
      ]);
    }
  };
  // const renderItem = ({ item }: { item: Partial<RequestData> }) => (
  //   <RequestItem {...{ item }} />
  // );

  // const _keyExtractor = (item: Partial<RequestData>, index: number) =>
  //   `${item.id}-${index}`;

  // const renderSeparator = () => (
  //   <View
  //     style={{
  //       height: verticalScale(3),
  //       backgroundColor: colors.background,
  //     }}
  //   />
  // );

  React.useEffect(() => {
    if (!loading) {
      myRequests.length === 0 && loadRequests();
      setSelectedColors(stateColors);
      setRequests(myRequests);
    }

    return () => {
      // cleanup;
      // dispatch(setData({ myRequests: [] }, "SetState"));
    };
  }, [loading]);
  return (
    <Layout>
      <View
        style={[
          styles.bar,
          styles.flexStartRow,
          { backgroundColor: colors.card },
        ]}>
        <TouchableOpacity
          onPress={loadRequests}
          style={[styles.flexStartRow, { marginHorizontal: scale(5) }]}>
          <Icon name="refresh" size={28} color={colors.text} />
          <Text style={{ ...fonts?.bold }}>{strings("shared.refresh")}</Text>
        </TouchableOpacity>

        {loading ? null : (
          <View style={[styles.flexStartRow, { marginHorizontal: scale(5) }]}>
            <Text style={{ color: colors.text, ...fonts?.bold }}>
              {`${strings("request.filter_title")}: `}
            </Text>
            {stateColors?.map((e, i) => (
              <Chip
                // icon={"close"}
                onPress={() => onFilterPress(e)}
                icon={(props) => (
                  <Icon
                    name={
                      selectedColors.find((selected) => selected === e)
                        ? "check"
                        : undefined
                    }
                    {...props}
                    // size={30}
                  />
                )}
                key={i}
                selectedColor={colors.onPrimary}
                // selected
                style={{ backgroundColor: e, marginHorizontal: 1 }}
                textStyle={{ color: e }}>
                .
              </Chip>
            ))}
          </View>
        )}
      </View>
      <View style={styles.scrollContainer}>
        {loading ? (
          <RequestSkeleton />
        ) : (
          requests.map((e, i) => <RequestItem item={e} />)
        )}
      </View>
    </Layout>
  );
};
/**
(
        <FlatList
          numColumns={2}
          data={myRequests}
          renderItem={renderItem}
          keyExtractor={_keyExtractor}
          ItemSeparatorComponent={renderSeparator}
          contentContainerStyle={styles.surface}
        />
      )
 */
export const MyOrdersScreen = MyOrders;

const styles = StyleSheet.create({
  surface: {
    flex: 1,
    paddingVertical: scale(5),
    // marginHorizontal: scale(20),
    // paddingVertical: scale(20),
    paddingHorizontal: scale(10),
    alignSelf: "center",
    // alignItems: "center",
    minWidth: "96%",
    // // flexDirection: "row",
  },
  scrollContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingHorizontal: scale(10),
    // marginBottom: verticalScale(15),
    // marginLeft: -scale(20),
  },
  bar: {
    height: 50,
    width: "96%",
    marginHorizontal: scale(10),
    marginVertical: scale(5),

    alignSelf: "center",
    borderRadius: scale(5),
  },
  flexStartRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    // flex: 1,
  },
});
