import { useState } from "react";
import { ThemeKey } from "./interfaces";
import { ThemeStore } from "./store";

type ThemeProps = ["dark" | "light", (theme: "dark" | "light") => void];

// set and get theme type
export const useThemeService = (): ThemeProps => {
  const [theme, setTheme] = useState<ThemeKey>("dark");
  ThemeStore.getTheme().then((data) => (data ? setTheme(data) : null));

  const toggleTheme = (name: ThemeKey) => {
    ThemeStore.setTheme(name).then(() => setTheme(name));
  };

  return [theme, toggleTheme];
};
