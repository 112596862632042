import { Text } from "@components/shared";
import { LocalizationContext } from "@lang/context";
import { useTheme } from "@react-navigation/native";
import { Theme, ThemeContext } from "@theme";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { SCREEN_WIDTH } from "@utils/constants";
import React, { useContext } from "react";
import { Platform, StyleSheet, Image, View } from "react-native";
import { Button, TextInput } from "react-native-paper";
import {
  Transition,
  Transitioning,
  TransitioningView,
} from "react-native-reanimated";
import { moderateScale, scale, verticalScale } from "react-native-size-matters";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "@store/sagas/authSaga";
import { AuthStateProps } from "@store/reducers/auth";

const transition = (
  <Transition.Sequence>
    <Transition.Out type="slide-top" />
    <Transition.Change interpolation="easeInOut" />
    <Transition.In type="slide-bottom" />
  </Transition.Sequence>
);

interface Props {}
const LoginScreen: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { colors, dark, fonts } = useTheme() as Theme;
  const { strings } = React.useContext(LocalizationContext);
  const [isSignup, setIsSignup] = React.useState(false);
  const ref = React.useRef<TransitioningView>(null);

  /** pull store data */
  const { loading } = useSelector(
    (state: { auth: AuthStateProps }) => state.auth
  );
  /** swaping between signup and login */
  const signup = () => {
    setIsSignup(true);
    ref.current?.animateNextTransition();
  };

  const signin = () => {
    setIsSignup(false);
    ref.current?.animateNextTransition();
  };
  /** signup action */
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const signupUser = () => {
    dispatch(setData({ loading: true }, "SetAuth"));
    dispatch(setData({ email, password }, "Signup"));
  };

  const loginUser = () => {
    dispatch(setData({ loading: true }, "SetAuth"));
    dispatch(setData({ email, password }, "Login"));
  };

  return (
    <View style={styles.container}>
      <Image
        source={
          dark
            ? require("@assets/images/logo-dark.png")
            : require("@assets/images/logo-light.png")
        }
        style={styles.logo}
      />
      <Transitioning.View
        transition={transition}
        ref={ref}
        // style={styles.container}
      >
        {!isSignup ? (
          <View style={[styles.box, { borderColor: colors.border }]}>
            <TextInput
              selectionColor={colors.placeholder}
              style={styles.input}
              mode="outlined"
              label="Email"
              placeholder="ahmed@ceba.edu"
              onChangeText={setEmail}
              theme={{ colors, dark, fonts }}
              placeholderTextColor={colors.placeholder}
            />
            <TextInput
              style={styles.input}
              secureTextEntry
              mode="outlined"
              label="Password"
              placeholder="password"
              onChangeText={setPassword}
              theme={{ colors, dark, fonts }}
            />
            <Button
              loading={loading}
              mode="contained"
              theme={{ colors, dark, fonts }}
              style={{
                ...Platform.select({
                  web: { marginVertical: 20 },
                }),
              }}
              icon={(props) => <Icon {...props} name="lock-open-check" />}
              onPress={loginUser}>
              {strings("auth.signin")}
            </Button>
            <Text>{strings("auth.no_account")}</Text>
            <Button
              mode="text"
              theme={{ colors, dark, fonts }}
              style={{
                ...Platform.select({
                  ios: { direction: "rtl" },
                  android: { direction: "rtl" },
                  web: { marginHorizontal: 20 },
                }),
              }}
              icon={(props) => <Icon {...props} name="plus" />}
              onPress={signup}>
              {strings("auth.signup")}
            </Button>
          </View>
        ) : (
          <View style={[styles.box, { borderColor: colors.border }]}>
            <TextInput
              selectionColor={colors.placeholder}
              style={styles.input}
              mode="outlined"
              label="Name"
              placeholder="الاسم الاول واسم العائلة"
              theme={{ colors, dark, fonts }}
              placeholderTextColor={colors.placeholder}
            />
            <TextInput
              selectionColor={colors.placeholder}
              style={styles.input}
              mode="outlined"
              label="Email"
              placeholder="ahmed@ceba.edu"
              onChangeText={setEmail}
              theme={{ colors, dark, fonts }}
              placeholderTextColor={colors.placeholder}
            />
            <TextInput
              style={styles.input}
              secureTextEntry
              mode="outlined"
              label="Password"
              placeholder="password"
              onChangeText={setPassword}
              theme={{ colors, dark, fonts }}
            />
            <TextInput
              style={styles.input}
              secureTextEntry
              mode="outlined"
              label="Confirm password"
              placeholder="confirm password"
              theme={{ colors, dark, fonts }}
            />
            <Button
              loading={loading}
              mode="contained"
              theme={{ colors, dark, fonts }}
              style={{
                ...Platform.select({
                  web: { marginVertical: 20 },
                }),
              }}
              icon={(props) => <Icon {...props} name="plus" />}
              onPress={signupUser}>
              {strings("auth.signup")}
            </Button>
            <Text>{strings("auth.returning_user")}</Text>
            <Button
              mode="text"
              theme={{ colors, dark, fonts }}
              style={{
                ...Platform.select({
                  web: { marginHorizontal: 20 },
                }),
              }}
              icon={(props) => <Icon {...props} name="lock" />}
              onPress={signin}>
              {strings("auth.signin")}
            </Button>
          </View>
        )}
      </Transitioning.View>
    </View>
  );
};

export const Login = LoginScreen;

const LOGO_WIDTH = SCREEN_WIDTH - scale(40);
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    borderRadius: 15,
    borderWidth: scale(1),
    padding: 15,
    ...Platform.select({
      ios: {
        width: SCREEN_WIDTH - moderateScale(30),
      },
      android: {
        width: SCREEN_WIDTH - moderateScale(30),
      },
      web: {
        marginHorizontal: 15,
        minWidth: 500,
      },
    }),
  },
  input: {
    marginVertical: verticalScale(10),
    textAlign: "left",
    ...Platform.select({
      web: {
        marginVertical: 10,
      },
    }),
  },

  logo: {
    marginVertical: verticalScale(10),
    // height: "100%",
    height: LOGO_WIDTH * (109 / 500),
    width: LOGO_WIDTH,
    ...Platform.select({
      web: {
        marginVertical: 10,
        height: 109,
        width: 500,
      },
    }),
  },
});
