import firebase from "firebase";
import "firebase/auth";
import { call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import {
  ActionType,
  AuthStateProps,
  DispatchedObj,
  User,
} from "@store/reducers/auth/interfaces";
import { errorHandler, ERRORS } from "@utils/error-handler";
import { get, post } from "@api";
import { strings } from "@lang/i18n";

export const setData = (
  payload: Partial<AuthStateProps>,
  type: ActionType
): DispatchedObj => ({
  type,
  payload,
});

export const getData = (type: ActionType) => ({
  type,
});
/**
 * signup user
 * @param data
 */
const login = async ({ email, password }: Partial<AuthStateProps>) => {
  const resp = await firebase
    .auth()
    .signInWithEmailAndPassword(email!, password!);
  return resp;
};
function* loginUser(data: DispatchedObj) {
  try {
    const { user } = yield login(data.payload);
    yield put(
      setData(
        {
          loading: false,
          authenticated: true,
          currentUser: user,
        },
        "SetAuth"
      )
    );
  } catch (error) {
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetAuth"
      )
    );
  }
}
/**
 * signup user
 * @param data
 */
const signup = async ({ email, password }: Partial<AuthStateProps>) => {
  const resp = await firebase
    .auth()
    .createUserWithEmailAndPassword(email!, password!);
  return resp;
};
function* signupUser(data: DispatchedObj) {
  try {
    const { user } = yield signup(data.payload);
    yield put(
      setData(
        {
          loading: false,
          authenticated: true,
          currentUser: user,
        },
        "SetAuth"
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetAuth"
      )
    );
  }
}
/**
 * user logout saga
 */
function* userLogoutSaga() {
  try {
    yield firebase.auth().signOut();
    yield put(
      setData(
        {
          loading: false,
          authenticated: false,
        },
        "SetAuth"
      )
    );
  } catch (error) {
    errorHandler(strings(ERRORS.ALREADY_LOGGED_OUT), "success");
  }
}
/**
 *
 * @param param0 gte current user
 */
function* getCurrentUser(data: DispatchedObj) {
  try {
    if (data.payload && data.payload.email) {
      const { exist, user } = yield get(`/users/details/${data.payload.email}`);
      yield put(
        setData(
          {
            currentUser: user,
            loading: false,
            authenticated: true,
          },
          "SetAuth"
        )
      );
    }
  } catch (error) {
    errorHandler(error, "danger");
  } finally {
  }
}
export default function* watchUserAuthentication(): Generator<
  StrictEffect,
  void,
  any
> {
  yield takeLatest("Login" as ActionType, loginUser);
  yield takeLatest("Signup" as ActionType, signupUser);
  yield takeLatest("CurrentUser" as ActionType, getCurrentUser);
  yield takeLatest("Logout" as ActionType, userLogoutSaga);
}
