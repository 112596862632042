import Constants from "expo-constants";
import { Dimensions, Platform } from "react-native";

/** global regex */
export enum FormType {
  Phone = "Phone",
  Name = "Name",
  Any = "Any",
}
export const regX = {
  Phone: /^5\d{8}$/,
  Name: /^(?!\s)([a-zء-ي ,.'-]+){3,30}$/i,
  // Name: /^([a-zA-Zء-ي ]+){3,30}$/,
  Any: /^\s*(?:\S\s*){0,}$/im,
};
// ===========
export const PAGE_SIZE = 17;

export type KeyboardEventName =
  | "keyboardWillShow"
  | "keyboardDidShow"
  | "keyboardWillHide"
  | "keyboardDidHide";

/**
 * is real device
 */
export const IS_REAL_DEVICE = Constants.isDevice;
/**
 * Device static screen values and props
 */
// screen height
export const SCREEN_HEIGHT = Dimensions.get("window").height;
// screen width
export const SCREEN_WIDTH = Dimensions.get("window").width;
// statusbar
export const STATUS_BAR = Constants.statusBarHeight;
// tabbar
export const TABBAR_HEIGHT = 49;
// is iphone x
export const IS_IPHONE_X = SCREEN_HEIGHT === 812 || SCREEN_HEIGHT === 896;
// is android device
export const IS_ANDROID = Platform.OS === "android";
// is ios device
export const IS_IOS = Platform.OS === "ios";
// is web
export const IS_WEB = Platform.OS === "web";
/**
 * screen Header Height
 */
export const HEADER_HEIGHT = SCREEN_WIDTH / 1.7;
/**
 * number-pad keyboard default
 */
export const numericKeyboardType = Platform.select({
  ios: "number-pad",
  android: "numeric",
});
// Keyboards events
export const KeyboardHideEvent: KeyboardEventName = Platform.select({
  android: "keyboardDidHide",
  default: "keyboardWillHide",
});

export const KeyboardShowEvent: KeyboardEventName = Platform.select({
  android: "keyboardDidShow",
  default: "keyboardWillShow",
});
