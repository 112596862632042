import React from "react";
import {
  StyleSheet,
  View,
  StyleProp,
  TextStyle,
  ViewProps,
  ViewStyle,
} from "react-native";
import { scale } from "react-native-size-matters";

import { Text } from "./Text";

interface Props extends ViewProps {
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}
const SeparatorComponent: React.FC<Props> = (props) => {
  const { title, titleStyle, style, containerStyle } = props;
  return !title ? (
    <View style={[styles.line, style, containerStyle]} />
  ) : (
    <View style={[styles.row, containerStyle]}>
      <View style={[styles.line, style]} />
      <Text style={[styles.title, titleStyle]}>{title}</Text>
      <View style={[styles.line, style]} />
    </View>
  );
};

export const Separator = React.memo(SeparatorComponent);

const styles = StyleSheet.create({
  line: {
    flex: 1,
    height: StyleSheet.hairlineWidth,
    backgroundColor: "gray",
  },
  row: {
    // flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: scale(13),
    color: "gray",
    paddingHorizontal: scale(5),
  },
});
