import firebase from "firebase";
import "firebase/auth";
import { call, put, StrictEffect, takeLatest } from "redux-saga/effects";
import {
  ActionType,
  WorkflowStateProps,
  DispatchedObj,
  RequestData,
  DepartmentData,
} from "@store/reducers/workflow/interfaces";
import { get, post } from "@api";
import _ from "lodash";

export const setData = (
  payload: Partial<WorkflowStateProps>,
  type: ActionType
): DispatchedObj => ({
  type,
  payload,
});

export const getData = (type: ActionType) => ({
  type,
});
/**
 * add request
 * @param data
 */

const addRequest = async ({ requesPayload }: Partial<WorkflowStateProps>) => {
  const data = await post(`request/add`, { ...requesPayload }, true);
  return data;
};

function* addRequestSaga(data: DispatchedObj) {
  try {
    const { request } = yield addRequest(data.payload);
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
    data.payload.cb!();
  }
}
/**
 * update request
 * @param param0
 * @returns
 */
const updateRequest = async ({
  requesPayload,
}: Partial<WorkflowStateProps>) => {
  const data = await post(
    `request/update/:requestId`,
    { ...requesPayload },
    true
  );
  return data;
};
function* updateRequestSaga(data: DispatchedObj) {
  try {
    const { request } = yield updateRequest(data.payload);
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
  }
}
/** load own requests */
const getColors = (data: RequestData[]) =>
  data.length ? _.uniq(data.map((e) => e.action.color)) : [];
const getOwnReq = async () => {
  const data = await get("my_requests", true);
  const colors = await getColors(data);
  return { data, colors };
};
function* loadOwnRequestSaga() {
  try {
    const { data, colors } = yield call(getOwnReq);
    yield put(
      setData(
        {
          myRequests: data,
          colors,
        },
        "SetState"
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
  }
}
/** load departments */
const buildTree = (list: DepartmentData[]) => {
  let map: any = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.parent]]) {
        list[map[node.parent]].children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};

const getDepts = async () => {
  const resp = await get("departments", false);
  const data = await buildTree(resp);
  console.log(data);

  return { data };
};
function* loadDepartmentsSaga() {
  try {
    const { data } = yield call(getDepts);
    yield put(
      setData(
        {
          departments:
            data.length && data[0].children && data[0].children.length
              ? data[0].children
              : [],
        },
        "SetState"
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(
      setData(
        {
          loading: false,
        },
        "SetState"
      )
    );
  }
}
export default function* watchWorkflow(): Generator<StrictEffect, void, any> {
  yield takeLatest("UpdateRequest" as ActionType, updateRequestSaga);
  yield takeLatest("AddRequest" as ActionType, addRequestSaga);
  yield takeLatest("LoadMyRequests" as ActionType, loadOwnRequestSaga);
  yield takeLatest("LoadDepartments" as ActionType, loadDepartmentsSaga);
}
