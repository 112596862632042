export default {
  shared: {
    morning: "صباح",
    afternoon: "مساء",
    evening: "مساء",
    login: "تسجيل الدخول",
    no_result: "لم يتم العثور على نتائج",
    back: "الرجوع",
    app_name: "اتفقنا",
    en: "English",
    ar: "العربية",
    refresh: "تحديث الصفحة",
  },
  menu: {
    home: "الرئيسية",
    Journal_list: "أعداد المجلة",
    support: "الدعم",
    guide: "دليل الباحث",
    about: "عن المجلة",
    dashboard: "لوحة إحصائية",
    new_order: "طلب جديد",
    track_order: "تتبع طلبك",
    orders: "قائمة الطلبات",
  },
  tabbar: {
    title: "اتفقنا",
  },
  request: {
    author: "المؤلف",
    new_order: "طلب جديد",
    back: "الرجوع",
    status: "الحالة",
    active: "نشط",
    inactive: "انتهى",
    filter_title: "تصفية القائمة",
    file: "افتح الملف",
    note: "الملاحظات",
  },
  auth: {
    name: "الاسم",
    phone: "رقم الجوال",
    previous: "السابق",
    next: "التالي",
    step1: "بياناتك",
    step1_msg: "أدخل معلوماتك الشخصية لمساعدتك على التفاعل مع الآخرين",
    step2: "الخدمات",
    step2_msg: "حدد المهن والخدمات التي تجيدها وتقدمها",
    step3: "موقعك",
    step3_msg: "حدد موقعك لنتمكن من مساعدتك بشكل أفضل",
    step4: "التحقق",
    step4_msg: "أدخل رمز التحقق المرسل إلى هاتفك",
    register: "التسجيل",
    yes: "نعم",
    no: "لا",
    is_pro_msg: "هل ترغب في التسجيل كمزود خدمة؟",
    service_option_msg: "التسجيل كـــ:",
    company: "شركة أو مؤسسة",
    individual: "فرد",
    resend_code: "إعادة ارسال",
    no_code: "لم تستلم رسالة نصية؟",
    default_location: "مدينة الرياض",
    select_region: "اختر المنطقة / الولاية",
    select_city: "اختر المدينة",
    select_district: "اختر الحي",
    picker_default_txt: "الخيارات",
    loading: "جار التحميل...",
    search_placeholder: "ابحث ...",
    search_error: "لم يتم العثور على نتيجة",
    signin_msg: "الرجاء إدخال رقم هاتفك لتسجيل الدخول",
    signin: "تسجيل الدخول",
    returning_user: "هل لديك حساب؟",
    signup: "تسجيل مستخدم جديد",
    no_account: "ليس لديك حساب؟",
    agreement_note_1: "عند التسجيل فإنك توافق على",
    terms_and_conditions: "الشروط والأحكام",
    agreement_note_2: "، و",
    privacy_policy: "سياسة الخصوصية",
    agreement_note_3: "، و",
    eula: "اتفاقية ترخيص المستخدم",
  },
  errors: {
    code: "رمز غير صالح! يرجى المحاولة مرة أخرى",
    login: "تسجيل دخول غير صالح. يرجى المحاولة مرة أخرى!",
    already_loggedout: "لقد قمت بتسجيل الخروج!",
    invalid_phone: "يجب أن يكون رقم هاتف صالحًا",
    invalid_name: "اسم غير صالح (من 3 إلى 30 حرفًا)",
  },
  user: {
    call: "اتصل بي",
    chat: "الدردشة",
    portfolio: "ألبوم العمل",
    rating: "التقييم",
    apply_filter: "تطبيق التغييرات",
    cancel_filter: "إلغاء",
    filter_title: "تصفية القائمة",
    all_cities: "كل المدن",
    all_districts: "كل الأحياء",
    sort_asc: "فرز حسب الأدنى",
    sort_desc: "فرز حسب الأعلى",
    sort_date: "فرز حسب التاريخ",
    add_review_placeholder: "أضف تقييم جديد ...",
    total_ratings: "إجمالي التقييمات",
    review_show_more: "...المزيد",
    send_review: "ارسل التقييم",
    terrible: "سيء جدًا 😞",
    bad: "سيء 😏",
    ok: "جيد 👍",
    very_good: "ممتاز 😚",
    wow: "رائع 😍",
    no_review_body: "لا يوجد تعليق",
    signin: "تسجيل الدخول",
    phone: "الهاتف",
    in_app_call: "مكالمة داخل التطبيق",
    close: "إغلاق",
    edit_profile: "ملفي الشخصي",
    update_profile: "حفظ التعديلات",
    service_provider: "مقدم خدمة",
    normal_user: "مستخدم عادي",
    user_IAM: "مسجل كــ:",
    service_type: "نوع الخدمة:",
    profile_updated_success: "رائع! تم تحديث ملفك الشخصي بنجاح",
    added_fav: "تمت الإضافة إلى قائمة المفضلة",
    Removed_fav: "تمت إزالته من قائمة المفضلة",
    exceed_fav: "لقد تجاوزت حد القائمة المفضلة لديك",
  },
  drawer: {
    home: "الرئيسية",
    bookmarks: "عملائي المفضلين",
    privacy: "سياسة الخصوصية",
    terms: "الشروط والأحكام",
    support: "الدعم",
    edit_profile: "تحرير الملف الشخصي",
    signout: "تسجيل الخروج",
    signin: "تسجيل الدخول",
    rating: "التقييم",
    signup: "تسجيل مستخدم جديد",
    returning_user: "هل لديك حساب؟",
    new_user: "هل أنت مستخدم جديد؟",
    dark_theme: "مظهر داكن",
    signout_alert: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    signout_alert_yes: "نعم! أنا متأكد",
    signout_alert_cancel: "إلغاء",
    portfolio: "ملف أعمالي",
    delete_file: "حذف الصورة",
    delete_file_alert:
      "سيتم حذف هذا الملف نهائيًا ولن تتمكن من استعادته لاحقًا. هل أنت متأكد أنك تريد متابعة الحذف؟",
    delete_file_alert_yes: "نعم. احذفه!",
    delete_file_alert_cancel: "إلغاء",
    admin_unavailable: "جميع وكلاء الدعم غير متاحين!",
    contacting_admin: "التواصل مع الدعم ...",
  },
  chat: {
    send: "أرسل",
    send_input_placeholder: "اكتب رسالة ...",
    loading_earlier: "عرض الرسائل السابقة",
    send_location: "أرسل موقعي",
    my_location: "موقعي 🌏",
    my_chat: "محادثاتي",
  },
  location: {
    permission_denied_title: "لا يمكن إرسال {{permissionName}} 😞",
    permission_denied_msg: `إذا كنت ترغب في استخدام هذه الميزة ، فستحتاج إلى منح إذن الوصول إلى {{permissionName}} في إعدادات هاتفك.`,
    permission_denied_ok_action: "انتقل إلى الإعدادات",
    permission_denied_cancel_action: "إلغاء",
  },
};
