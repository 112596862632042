import React from "react";
import {
  View,
  StyleProp,
  ViewStyle,
  StyleSheet,
  TextStyle,
} from "react-native";
import { moderateScale, scale } from "react-native-size-matters";
import { useTheme } from "@react-navigation/native";
// local imports
import { Theme } from "@theme";
import { Text } from "./Text";
// component interface
interface Props {
  plainSurface?: boolean;
  title: string;
  subTitle: string;
  titleStyle?: StyleProp<TextStyle>;
  subTitleStyle?: StyleProp<TextStyle>;
  containerStyle?: StyleProp<ViewStyle>;
}
/**
 * It renders header title and subtitle
 * @param props
 */
const HeaderTextComponent: React.FC<Props> = (props) => {
  const { colors, fonts } = useTheme() as Theme;

  const {
    containerStyle,
    subTitleStyle,
    titleStyle,
    title,
    subTitle,
    plainSurface,
  } = props;
  return (
    <View style={[styles.introTextContainer, containerStyle]}>
      <Text
        style={[
          styles.title,
          {
            color: plainSurface ? colors.text : colors.onPrimary,
            ...fonts?.bold,
          },
          titleStyle,
        ]}>
        {title}
      </Text>
      <Text
        style={[
          styles.subTitle,
          {
            color: plainSurface ? colors.text : colors.onPrimary,
            ...fonts?.regular,
          },
          subTitleStyle,
        ]}>
        {subTitle}
      </Text>
    </View>
  );
};
// component styles
const styles = StyleSheet.create({
  introTextContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
  },
  title: {
    fontSize: scale(24),
    marginVertical: moderateScale(10),
  },
  subTitle: {
    fontSize: scale(14),
    marginHorizontal: scale(15),
    textAlign: "center",
  },
});

export const HeaderText = React.memo(HeaderTextComponent);
