import initialState from "./initialState";
import { Action, ActionType, WorkflowStateProps } from "./interfaces";
import produce from "immer";
/**
 * react services reducers for state management
 */
const reducers = (
  state = initialState,
  { type, payload }: Action<ActionType, any>
): WorkflowStateProps =>
  produce(state, (draftState) => {
    switch (type) {
      // update any state data
      case "SetState":
        return {
          ...draftState,
          ...payload,
        };
      // clear state
      case "ResetState": {
        return initialState;
      }
      // return the state is no action or invalid action type has been sent to the reducer
      default:
        return draftState;
    }
  });

export default reducers;
