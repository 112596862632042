import { RouteProp, useRoute } from "@react-navigation/core";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TabThreeParamList } from "@navigations/types";
import { useSelector } from "react-redux";
import { RequestData, WorkflowStateProps } from "@store/reducers/workflow";

import { MobilePDFReader } from "react-read-pdf";

import { RequestItem, Layout, RequestItemDetails } from "@components";
import { ActivityIndicator } from "react-native-paper";
const RequestScreenComponent = () => {
  const [request, setRequest] = React.useState<Partial<RequestData>>();

  const { params } = useRoute<RouteProp<TabThreeParamList, "RequestScreen">>();

  const [pageNumber, setPageNumber] = React.useState(1);

  const { myRequests, loading, activities, colors: stateColors } = useSelector(
    (state: { workflow: WorkflowStateProps }) => state.workflow
  );

  const loadActivities = () => {};
  React.useEffect(() => {
    // pdfjs.GlobalWorkerOptions.workerSrc = "pdf.worker.min.js";
    if (params && params.id) {
      const req = myRequests.find((e) => e.id === params.id);
      setRequest(req);
      loadActivities();
    }
    return () => {
      // cleanup;
    };
  }, [request]);
  return (
    <Layout>
      {request ? <RequestItemDetails item={request!} /> : null}
      {loading ? (
        <ActivityIndicator />
      ) : (
        activities.map((e, i) => (
          <Text>
            {e.requestId} {e.status_ar}{" "}
          </Text>
        ))
      )}
      {request ? <MobilePDFReader url={request.file} /> : null}
    </Layout>
  );
};

export const RequestScreen = RequestScreenComponent;

const styles = StyleSheet.create({});
