import { Ionicons } from "@expo/vector-icons";
import { LocalizationContext } from "@lang/context";
import { useTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { Theme } from "@theme";
import * as React from "react";

import {
  DashboardScreen,
  MyOrdersScreen,
  NewOrderScreen,
  RequestScreen,
} from "@screens";
import {
  BottomTabParamList,
  TabOneParamList,
  TabThreeParamList,
  TabTwoParamList,
} from "./types";
import { screenWithHeader } from "./options";
// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab

/**
 * Dashboard Stack
 */
const TabOneStack = createStackNavigator<TabOneParamList>();

function TabOneNavigator() {
  const { colors, dark } = useTheme() as Theme;
  const { strings } = React.useContext(LocalizationContext);

  return (
    <TabOneStack.Navigator>
      <TabOneStack.Screen
        name="DashboardScreen"
        component={DashboardScreen}
        options={(props) =>
          screenWithHeader(
            {
              ...props,
              headerStyle: {
                borderBottomWidth: 1,
                borderBottomColor: colors.background,
              },
            },
            strings("auth.signin")
          )
        }
      />
    </TabOneStack.Navigator>
  );
}

/**
 * New order stack
 */
const TabTwoStack = createStackNavigator<TabTwoParamList>();

function TabTwoNavigator() {
  const { colors, dark } = useTheme() as Theme;
  const { strings } = React.useContext(LocalizationContext);

  return (
    <TabTwoStack.Navigator>
      <TabTwoStack.Screen
        name="NewOrderScreen"
        component={NewOrderScreen}
        options={(props) =>
          screenWithHeader(
            {
              ...props,
              headerStyle: {
                borderBottomWidth: 1,
                borderBottomColor: colors.background,
              },
            },
            strings("auth.signin")
          )
        }
      />
    </TabTwoStack.Navigator>
  );
}

/**
 * New order stack
 */
const TabThreeStack = createStackNavigator<TabThreeParamList>();

function TabThreeNavigator() {
  const { colors, dark } = useTheme() as Theme;
  const { strings } = React.useContext(LocalizationContext);

  return (
    <TabThreeStack.Navigator initialRouteName="MyOrdersScreen">
      <TabThreeStack.Screen
        name="MyOrdersScreen"
        component={MyOrdersScreen}
        options={(props) =>
          screenWithHeader(
            {
              ...props,
              headerStyle: {
                borderBottomWidth: 1,
                borderBottomColor: colors.background,
              },
            },
            strings("auth.signin")
          )
        }
      />
      <TabThreeStack.Screen
        name="RequestScreen"
        component={RequestScreen}
        options={(props) =>
          screenWithHeader(
            {
              ...props,
              headerStyle: {
                borderBottomWidth: 1,
                borderBottomColor: colors.background,
              },
            },
            strings("auth.signin")
          )
        }
      />
    </TabThreeStack.Navigator>
  );
}

/** tabs container */
const BottomTab = createStackNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const { colors, dark, fonts } = useTheme() as Theme;

  return (
    <BottomTab.Navigator
      initialRouteName="TabOne"
      screenOptions={{ headerShown: false }}>
      {/* dashboard */}
      <BottomTab.Screen name="TabOne" component={TabOneNavigator} />
      {/*  new order */}
      <BottomTab.Screen name="TabTwo" component={TabTwoNavigator} />
      {/* request data */}
      <BottomTab.Screen name="TabThree" component={TabThreeNavigator} />
    </BottomTab.Navigator>
  );
}
