import I18n from "i18n-js";
import en from "./en";
import ar from "./ar";

I18n.defaultLocale = "ar";

I18n.locale = "ar";

I18n.fallbacks = true;
I18n.translations = {
  ar,
  en,
};

export function strings(name: string, params = {}) {
  console.log("strings lang:", I18n.locale);

  return I18n.t(name, params);
}

export default I18n;
