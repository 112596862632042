import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { persistStore, persistReducer } from "redux-persist";
// import {
//   reducer as networkReducer,
//   createNetworkMiddleware,
// } from "react-native-offline";
import { composeWithDevTools } from "redux-devtools-extension";
import { authReducer } from "@store/reducers/auth";
import { workflowReducer } from "@store/reducers/workflow";
import rootSaga from "./sagas";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "root",
  // stateReconciler: autoMergeLevel2,
  // Storage Method (React Native)
  storage: AsyncStorage,
  // Whitelist (Save Specific Reducers)
  whitelist: [],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: ["network", "auth", "workflow"],
};

const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  // const networkMiddleware = createNetworkMiddleware({
  //   queueReleaseThrottle: 1000,
  // });

  const middlewares = [
    sagaMiddleware,
    // networkMiddleware
  ];
  let enhancers;
  if (process.env.NODE_ENV === "development") {
    enhancers = composeWithDevTools(applyMiddleware(...middlewares));
  } else {
    enhancers = compose(applyMiddleware(...middlewares));
  }
  const rootReducer = combineReducers({
    auth: authReducer,
    workflow: workflowReducer,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(persistedReducer, enhancers);
  // Middleware: Redux Persist Persister
  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;
