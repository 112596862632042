import { DarkTheme, DefaultTheme } from "@react-navigation/native";
import { Theme } from "./interfaces";
import configureFonts from "./fonts";
// dark theme props
export const dark: Theme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    primary: "#AA70FA",
    accent: "#AA70FA",
    onPrimary: "#ffffff",
    surface: "#000000",
    placeholder: "#FFFFFF54",
    color: "#ffffff",
    secondary: "#C3A057",
    onBackground: "#cecece",
    onSurface: "#e1e1e1",
    onSecondary: "#ffffff",
    notification: "red",
    error: "orange",
    text: "#f9f9f9",
    card: "#19191C",
    border: DefaultTheme.colors.border,
    skeleton: "#767676",
    // background: "#19191C",
  },
  fonts: configureFonts(),
};
// light theme props
export const light: Theme = {
  ...DefaultTheme,
  colors: {
    // 007774 1FA5D6
    ...DefaultTheme.colors,
    primary: "#012A5C",
    accent: "#012A5C",
    onPrimary: "#ffffff",
    background: "#f6f6f6",
    surface: "#ffffff",
    placeholder: "#00000054",
    color: "#000000",
    secondary: "#C3A057",
    onBackground: "#101010",
    onSurface: "#1c1c1c",
    onSecondary: "#ffffff",
    error: "red",
    notification: "red",
    skeleton: "#E0E1EA",
  },
  fonts: configureFonts(),
};
