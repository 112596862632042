import { fork, all, StrictEffect } from "redux-saga/effects";
// import { networkSaga } from "react-native-offline";
import watchUserAuthentication from "./authSaga";
import watchWorkflow from "./workflowSaga";
export default function* rootSaga() {
  yield all([
    fork(watchUserAuthentication),
    fork(watchWorkflow),
    // fork(networkSaga as any, { pingInterval: 2000 }),
  ]);
}
