import { Text } from "@components/shared";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import dayjs from "dayjs";
import { Surface } from "react-native-paper";

// import PDFReader from "rn-pdf-reader-js";

import { LocalizationContext } from "@lang/context";
import { useLinkTo, useRoute, useTheme } from "@react-navigation/native";
import { RequestData } from "@store/reducers/workflow";
import { Theme } from "@theme";
import React, { useContext } from "react";
import { StyleSheet, View, TouchableOpacity, Linking } from "react-native";
import { scale } from "react-native-size-matters";

interface Props {
  item: Partial<RequestData>;
}
const RequestItemComponent: React.FC<Props> = (props) => {
  const { item } = props;
  const linkTo = useLinkTo();
  const { colors, fonts, dark } = useTheme() as Theme;
  const { strings, locale, isRTL } = useContext(LocalizationContext);

  const onPress = () => linkTo(`/pdf`);
  return (
    <Surface
      theme={{ colors, dark, fonts }}
      style={[styles.container, { borderLeftColor: item.action?.color }]}>
      <View style={styles.surface}>
        <View style={styles.row}>
          <View
            style={[
              styles.flexStartRow,
              {
                backgroundColor: item.action?.color,
                borderRadius: scale(5),
                padding: scale(2.5),
              },
            ]}>
            {/* <Text>{`${strings("request.status")}: `}</Text> */}
            <Text style={{ color: colors.onPrimary, ...fonts?.bold }}>
              {eval(`item.action?.name_${locale}`)}
            </Text>
          </View>
          <View style={styles.flexStartRow}>
            <Icon
              name="timeline-clock"
              color={colors.primary}
              size={20}
              style={{ marginTop: -4 }}
            />
            <Text
              numberOfLines={1}
              style={[styles.text, { fontSize: 12, ...fonts?.bold }]}>
              {dayjs(item.updatedAt).format("DD-MM-YYYY hh:mm A")}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View
            style={[
              styles.flexStartRow,
              { flex: 1, direction: isRTL ? "rtl" : "ltr" },
            ]}>
            <Icon name="book" color={colors.text} size={20} />
            <Text
              numberOfLines={1}
              style={[
                styles.text,
                { flex: 1, direction: isRTL ? "rtl" : "ltr" },
              ]}>
              {item.title}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.flexStartRow}>
            <Icon name="account" color={colors.text} size={20} />
            <Text
              numberOfLines={1}
              style={[styles.text, { fontSize: 12, ...fonts?.bold }]}>
              {item.user?.name}
            </Text>
          </View>
          <View style={[styles.flexStartRow, { marginHorizontal: scale(5) }]}>
            <Icon
              name="circle"
              color={item.isActive ? "green" : "red"}
              size={20}
            />
            <Text
              numberOfLines={1}
              style={[styles.text, { fontSize: 12, ...fonts?.bold }]}>
              {item.isActive
                ? strings("request.active")
                : strings("request.inactive")}
            </Text>
          </View>
          <View style={styles.flexStartRow}>
            <Icon
              name="tree-outline"
              color={colors.primary}
              size={20}
              style={{ marginTop: -4 }}
            />
            <Text
              numberOfLines={1}
              style={[styles.text, { fontSize: 12, ...fonts?.bold }]}>
              {eval(`item.department?.name_${locale}`)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <TouchableOpacity
            style={styles.flexStartRow}
            onPress={() => Linking.openURL(item.file!)}>
            <Icon
              name="tree-outline"
              color={colors.primary}
              size={20}
              style={{ marginTop: -4 }}
            />
            <Text
              numberOfLines={1}
              style={[styles.text, { fontSize: 12, ...fonts?.bold }]}>
              {strings("request.file")}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.row}>
        {/* <PDFReader source={{ uri: item.file }} /> */}
        <View style={{ flex: 1, paddingVertical: 10 }}>
          <Text
            style={[
              styles.text,
              { fontSize: 12, paddingVertical: 5, ...fonts?.bold },
            ]}>
            {strings("request.note")}
          </Text>
          <Text
            style={[
              styles.text,
              {
                textAlign: "left",
                fontSize: 12,
                lineHeight: 18,
                ...fonts?.medium,
              },
            ]}>
            {item.last_note}
          </Text>
        </View>
      </View>
    </Surface>
  );
};

export const RequestItemDetails = React.memo(RequestItemComponent);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    marginHorizontal: 10,
    marginVertical: 10,
    borderRadius: 15,
    minWidth: "40%",
    overflow: "hidden",
    borderLeftWidth: 5,
  },
  surface: { flex: 1 },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    paddingHorizontal: scale(5),
    paddingVertical: scale(2.5),
  },
  flexStartRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    // flex: 1,
  },
  text: {
    paddingHorizontal: scale(3),
    fontSize: 16,
    // flex: 1,
  },
});
